@import "~bootstrap";

@import "~@fortawesome/fontawesome-free/css/fontawesome.min.css";
@import "~@fortawesome/fontawesome-free/css/all.css";




:root {

  --s7acolor: #0082c9;
  --ologoRed: #cc0000;
  --ologoOrange: #cc6600;
  --ologoYellow: #cc9900;

  --gray70: #707070;
  --gray7C: #7c7c7c;
  --grayAA: #aaaaaa;

  --white: #ffffff;
  --black: #111111;

  --color-error: #f44336;
  --color-warning: #ff9800;
  --color-success: #4caf50;
  --color-info: #2196f3;
  --color-question: #9e9e9e;


  --textprimary: var(--black);
  --texticon: var(--iconbase);
  --textwhite: var(--white);
  --textblack: var(--black);

  --backgroundFooter: var(--ologoRed);
  --backgroundSections1: #cc990008;
  --backgroundSections2: #00000000;

  --iconbase: var(--gray7C);

  --shadowImg: var(--grayAA);

  --ctrlBorder: var(--grayAA);


}


.divider {
  width: 10%;
  margin: 0 auto;
  height: 4px;
  background: var(--ologoRed);
  border-radius: 5px;
}


#end-reference {
  width: 100%;
}

.main-navbar-container {
  .navbar {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);

    .navbar-toggler {
      color: var(--iconbase);
    }

    .navbar-collapse {
      ul {
        margin: 0 auto !important;

        li {
            text-align: center;

          a {
            @include media-breakpoint-up(lg) { display: grid; }

            @include media-breakpoint-down(lg) { font-size: 20px; }

            color: var(--texticon);

            i {
              color: inherit;
            }

            &.active {
              color: var(--ologoYellow);
            }
          }
        }
      }
    }
  }
}



.main-bottombar-container {
  margin-top: 0;
  padding-bottom: 12px;
  min-height: 100px;
  background: var(--backgroundFooter);
  color: var(--textwhite);
  font-size: 12px;
  opacity: 0.0;
  border: none;

  h6 {
    font-weight: bold;
    text-align: center;
    font-size: 13px;
    letter-spacing: 3px;
    margin: 0;
  }

  .main-bottombar-main-row {
    padding-top: 8px;
  }

  .main-bottombar-legal {
    p {
      margin: 0 0 3px 0;
      padding: 0 0 0 10px;
      border-bottom: 1.5px solid var(--textwhite);

      a {
        text-decoration: none;
        color: var(--textwhite);
      }
    }

    .main-bottombar-vat-rea {
      font-size: 11px;
      margin-top: 8px;
    }
  }

  .main-bottombar-sections {

    li {
      margin-bottom: 3px;

      a {
        color: var(--textwhite);
        text-decoration: none;
      }
    }
  }

  .main-bottombar-logo {
    width: fit-content;
    float: right;
    text-align: center;
    margin-top: 9px;

    &.mobile {
      width: 100%;
      p {
        text-align: center;
      }

      a {
        padding-left: 6px;
      }
    }

    p {
      margin: 0 0 5px 0;
    }

    a {
      text-decoration: none;

      img {
        width: 43px;
      }

      p {
        color: var(--s7acolor);
      }
    }
  }

  .main-bottombar-carbon {
    position: absolute;
    bottom: 16px;

    a {
      color: var(--s7acolor);
      text-decoration: none;
    }

    i {
      color: var(--color-success);
    }
  }

  .mobile-view {
    font-size: 16px;

    .main-bottombar-legal {
      padding: 15px 0;

      h6 {
        font-size: inherit;
      }

      p {
        margin: 0 0 3px 0;
        padding: 12px 0 0 10px;
        border-bottom: 1.5px solid var(--textwhite);
        text-align: center;

        a {
          text-decoration: none;
          color: var(--textwhite);
        }
      }

      .main-bottombar-vat-rea {
        font-size: inherit;
      }
    }

    .main-bottombar-sections {
      padding: 15px 0 48px 0;

      h6 {
        font-size: inherit;
      }

      ul {
        li {
          float: left;
          margin: 0 53px 16px 0;
        }
      }
    }

    .main-bottombar-logo {
      float: none;
      margin: 0 auto;
    }

    .main-bottombar-carbon {
      position: relative;
      text-align: center;
      margin-top: 37px;
      margin-bottom: 45px;
    }
  }
}




